.firstSectio .right {
    color: black;
}

.firstSectio{
    display: flex;
    justify-content: space-around;
    margin: 140px 3;
    color: black;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;

}
.right img{
    width:100%;
}

.left {
    text-align: center;
    padding: 65px;
    color: white;
    font-size: 1.2rem;
}

left label {
    color: black;
    font-size: 2rem;
}

.red {
    color: aqua;
}

.button {
    text-decoration: none;
    color: rgba(255, 255, 255, 0.8);
    background: rgb(145, 92, 182);
    padding: 0px 24px;
    border-radius: 4px;
    font-weight: normal;
    text-transform: uppercase;
    transition: all 0.2s ease-in-out;
  }
  
  .glow-button:hover {
    color: rgba(255, 255, 255, 1);
    box-shadow: 0 5px 15px rgba(145, 92, 182, .4);
  }

.lef {

    text-align: center;
    padding: 0px;
    color: rgb(0, 0, 0);
    font-size: 2rem;

}