.contact-container {
  max-width: 600px;
  margin: 100px auto;
  padding: 20px;
  background-color: #333; /* Dark background color */
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2); /* Slightly darker shadow */
  color: white; /* White text for better contrast */
}

h2 {
  text-align: center;
  margin-bottom: 20px;
  color: #fff; /* White color for heading */
}

label {
  margin-bottom: 5px;
  display: block;
  font-weight: bold;
  color: #fff; /* White color for labels */
}

input,
textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #555; /* Darker border for input fields */
  border-radius: 4px;
  background-color: #444; /* Darker background for inputs */
  color: #fff; /* White text for inputs */
}

input:focus,
textarea:focus {
  border-color: #007BFF; /* Keep the focus color blue */
  outline: none;
}

.submit-button {
  width: 100%;
  padding: 10px;
  background-color: #007BFF; /* Blue background for submit button */
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.submit-button:hover {
  background-color: #0056b3; /* Darker blue on hover */
}

.error {
  color: red; /* Keep error message color red */
  font-size: 12px;
  margin-top: -10px;
  margin-bottom: 10px;
}
