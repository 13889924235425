/* Global Styles */
body {
    background: rgba(0, 0, 33);
    color: white;
    font-family: 'Algerian', sans-serif;
    margin: 0;
    padding: 0;
}
nav {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 70px;
    background-color: rgb(23, 23, 93);
}

nav ul {
    display: flex;
    justify-content: center;
}

nav ul li {
    list-style: none;
    margin: 0 23px;
}

nav ul li a {
    text-decoration: none;
    color: white;
}

nav ul li a:hover {
    text-decoration: none;
    color: rgb(8, 236, 244);
    font-size: 1.2rem;
}


/* Intro Section */
.intro-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 20px;
}

.intro-text {
    font-size: 2rem;
    margin-bottom: 20px;
}

.highlight {
    color: blueviolet;
}

.intro-image img {
    width: 100%;
    max-width: 250px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
}

.custom-hr {
    border: none;
    height: 1px;
    background: #9c97f1;
    margin: 40px 0;
}

/* Experience Section */
.experience-section {
    text-align: center;
    padding: 20px;
}

.experience-list {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.experience-item {
    background: #1a1a2e;
    padding: 15px;
    border-radius: 8px;
}

.experience-title {
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 10px;
}

.experience-desc {
    color: gray;
    font-size: 0.9rem;
}

/* Footer */
.footer-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: #0e0e1a;
}

.footer-brand h3 {
    margin-bottom: 10px;
}

.footer-links ul {
    list-style: none;
    padding: 0;
    display: flex;
    gap: 15px;
    flex-wrap: wrap;
}

.footer-text {
    text-align: center;
    color: gray;
    padding: 10px;
}

/* Responsive Styles */
@media (min-width: 768px) {
    .intro-section {
        flex-direction: row;
        justify-content: space-between;
        text-align: left;
        padding: 40px;
    }

    .intro-text {
        font-size: 3rem;
        margin: 0;
    }

    .experience-list {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-around;
    }

    .experience-item {
        flex: 1 1 45%;
        margin: 10px;
    }

    .footer-container {
        flex-direction: row;
        justify-content: space-around;
    }
}
